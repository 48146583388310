<template>

<app-content :loading="is.loading">

	<app-content-body v-if="!is.loading" :is-call="true">

		<app-content-call :loading="is.confirming" icon="ticket" :theme="theme" :title="title" :text="text" :has-cancel="true" :disabled="disabled" :button="button" v-on:click="onConfirmClick" v-on:cancel="onCancelClick">

			<div class="event">

				<div class="event-item is-name"><small>Event</small>{{ event.name }}</div>
				<div class="event-item"><small>Day</small>{{ $filter('formatDate', event.date, 'dddd') }}</div>
				<div class="event-item"><small>Start time</small>{{ $filter('formatDate', event.date, $hourFormat) }}</div>
				<div class="event-item" v-if="isSingle && !sale"><small>Cost</small>{{ cost }}</div>
				<div class="event-item" v-if="event.ticket.session.can.claim"><small>Cost</small>Free</div>
				<div class="event-item" v-if="sale && event.ticket.session.can.refund"><small>Cost</small>{{ sale.price | formatMoney }}</div>

			</div>

			<com-clashes :clashes="clashes" v-if="theme === 'blue'" />

			<template v-if="!isSingle && event.ticket.session.can.purchase">

				<app-addon v-for="item in items" layout="list" :key="item.id" button="Purchase" :item="item" v-on:add="onSelectTicket" />

			</template>

			<div class="event is-empty" v-if="event.ticket.session.can.purchase && !items.length">

				Sorry, there are no tickets currently available to purchase.

			</div>

		</app-content-call>
	
	</app-content-body>

</app-content>

</template>

<script>

import Vue from 'vue'

export default {

	components: {
		'com-clashes': () => import('./common/Clashes')
	},

	data: function() {

		return {
			is: {
				loading: true,
				confirming: false
			},
			event: {},
			sale: false,
			clashes: [],
			items: []
		}

	},

	created: function() {

		this.$store.commit('basket/reset')

		this.load();

	},

	beforeDestroy: function() {

		this.$store.commit('basket/reset')

	},

	computed: {

		canDoSomething: function() {

			return this.ticket.session.can.purchase || this.ticket.session.can.refund || this.ticket.session.can.claim || this.ticket.session.can.cancel || this.ticket.session.can.consume || this.ticket.session.can.revoke 

		},

		disabled: function() {

			return false

		},

		isSingle: function() {

			return this.optionCount === 1

		},

		optionCount: function() {

			var count = 0

			this.$_.each(this.items, function(item) {

				if (item.variants) {

					count += item.variants.length

				} else {

					count++

				}

			})

			return count

		},

		ticket: function() {

			return this.event.ticket

		},

		title: function() {

			if (this.ticket.session.can.purchase) {

				return 'Purchase Ticket'

			} else if (this.ticket.session.can.refund) {

				return 'Refund Ticket'

			} else if (this.ticket.session.can.claim) {

				return 'Confirm Ticket'

			} else if (this.ticket.session.can.cancel) {

				return 'Cancel Ticket'

			} else if (this.ticket.session.can.consume) {

				return 'Use Ticket'

			} else if (this.ticket.session.can.revoke) {

				return 'Don\'t Use Ticket'

			} else {

				return false

			}

		},

		text: function() {

			if (this.ticket.session.can.purchase || this.ticket.session.can.claim || this.ticket.session.can.consume) {

				return this.event.ticket.text.office_confirm

			} else if (this.ticket.session.can.refund || this.ticket.session.can.cancel || this.ticket.session.can.revoke) {

				return this.event.ticket.text.office_cancel

			} else {

				return false

			}

		},

		button: function() {

			if (this.ticket.session.can.purchase) {

				return this.isSingle ? 'Purchase' : false

			} else if (this.ticket.session.can.claim || this.ticket.session.can.refund || this.ticket.session.can.cancel || this.ticket.session.can.consume || this.ticket.session.can.revoke) {

				return 'Confirm'

			} else {

				return false

			}

		},

		cost: function() {

			if(this.event.ticket.purchase.enabled) {
				
				return (this.isSingle) ? this.$options.filters.formatMoney(this.items[0].price) : false
				
			} else {
				
				return 'Free'

			}

		},

		theme: function() {

			return this.isHolder && !this.ticket.session.can.consume ? 'red' : 'blue'

		},

		isHolder: function() {

			return this.event.ticket.session.is.holder

		}

	},

	methods: {

		hasAdded: function(id, variant) {

			var added = this.$_.filter(this.$store.getters['basket/items'], function(addon) {

				return addon.item.id === id && (variant === false || addon.variant.id === variant)

			})

			return (added.length) ? added : false

		},

		onSelectTicket: function(e) {

			var item = this.$_.findWhere(this.items, {
				id: e.item
			})

			var variant = (e.variant) ? this.$_.findWhere(item.variants, {
				id: e.variant
			}) : false

			if (item.variants && !e.variant) {

				variant = item.variants[0]

			}

			e.variant = variant ? variant.id : false

			if (this.hasAdded(e.item, e.variant)) {

				this.$store.commit('basket/remove', {
					badge: this.$store.getters['session/badge'].id,
					item: item,
					variant: variant
				})

			} else {

				this.$store.commit('basket/reset', {
					single: true,
					type: this.$constants.registration.sale.type.ticket,
					redirect: process.env.VUE_APP_CALLBACK + this.$route.params.organisation + '/' + this.$route.params.convention + '/schedule/events/' + this.event.id,
					params: {
						event: this.event.id,
						ticket: item.ticket
					}
				})

				this.$store.commit('basket/add', {
					badge: this.$store.getters['session/badge'].id,
					item: item,
					variant: variant,
					quantity: 1
				})

				this.$pubsub.$emit('basket.review')

			}

			this.selectedItem = e.item

		},

		onCancelClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Event'
			})

		},

		onConfirmClick: async function() {

			if (this.event.ticket.session.can.purchase) {

				if (this.isSingle) {

					this.$store.commit('basket/reset', {
						single: true,
						type: this.$constants.registration.sale.type.ticket,
						redirect: process.env.VUE_APP_CALLBACK + this.$route.params.organisation + '/' + this.$route.params.convention + '/schedule/events/' + this.event.id,
						params: {
							event: this.event.id,
							ticket: this.items[0].ticket
						}
					})

					this.$store.commit('basket/add', {
						badge: this.$store.getters['session/badge'].id,
						item: this.items[0],
						variant: this.items[0].variants ? this.items[0].variants[0] : false,
						quantity: 1
					})

					this.$pubsub.$emit('basket.review')

				}

			} else if (this.event.ticket.session.can.refund) {

				this.is.confirming = true

				this.$api.delete('convention/schedule/event/' + this.$route.params.id + '/ticket').then(function() {

					this.$notify({
						message: 'Your ticket was successfully refunded.'
					})

					this.$router.push({
						name: 'Convention.Schedule.Event'
					})

				}.bind(this))

			} else if (this.event.ticket.session.can.claim || this.event.ticket.session.can.consume) {

				this.is.confirming = true

				this.$api.post('convention/schedule/event/' + this.$route.params.id + '/ticket').then(function() {

					this.$notify({
						message: 'Your ticket is confirmed!.'
					})

					this.$router.push({
						name: 'Convention.Schedule.Event'
					})

				}.bind(this))

			} else if (this.event.ticket.session.can.cancel || this.event.ticket.session.can.revoke) {

				this.is.confirming = true

				this.$api.delete('convention/schedule/event/' + this.$route.params.id + '/ticket').then(function() {

					this.$notify({
						message: 'Your ticket was successfully cancelled.'
					})

					this.$router.push({
						name: 'Convention.Schedule.Event'
					})

				}.bind(this))

			}

		},

		load: function() {

			this.$api.get('convention/schedule/event/' + this.$route.params.id + '/ticket').then(function(response) {

				Vue.set(this, 'event', response.event)
				this.clashes = response.clashes
				this.sale = response.sale
				this.items = response.items

				if (!this.canDoSomething) {

					this.$router.push({
						name: 'Convention.Schedule.Event'
					})

					this.$notify({
						message: (this.ticket.session.is.seated) ? 'You are unable to cancel your ticket for this event.' : 'You are unable to attend this event.',
						type: 'error'
					})

				} else {

					this.is.loading = false

				}

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.event {
	display: flex;
	margin: 20px 0px;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 10px;
	width: 100%;
}

.event-item {
	padding: 10px 20px;
	flex-shrink: 0;
	font-weight: 400;
	font-size: 16px;
}

.event-item.is-name {
	flex-grow: 1;
}

.event-item small {
	display: block;
	font-weight: 300;
	font-size: 12px;
	color: #666;
	margin-bottom: 2px;
}

.event.is-empty {
	padding: 20px;
    align-items: center;
    justify-content: center;
    margin: 0px;
    background-color: #dd4848;
    border: 0px;
    color: #fff;
    font-weight: 400;
}

</style>
